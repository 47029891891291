/* 400 */
@font-face {
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/worksans/v17/QGYsz_wNahGAdqQ43Rh_fKDp.woff2)
    format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}

/* 500 */
@font-face {
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/worksans/v17/QGYsz_wNahGAdqQ43Rh_fKDp.woff2)
    format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}

/* 600 */
@font-face {
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/worksans/v17/QGYsz_wNahGAdqQ43Rh_fKDp.woff2)
    format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}

/* 700 */
@font-face {
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/worksans/v17/QGYsz_wNahGAdqQ43Rh_fKDp.woff2)
    format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}

body,
.btn,
textarea,
input,
button,
select,
optgroup,
textarea,
.tooltip,
.popover {
  font-family: "Work Sans", sans-serif;
}

.font-weight-bold {
  font-weight: bold;
}

.hero {
  height: 449px;
}

.control-1 {
  top: -125px;
  left: -70px;
  z-index: -1;
}

a,
.text-danger,
.welcome-text,
.btn-outline-danger {
  color: #e11c22;
}

.text-danger {
  color: #e11c22 !important;
}

a:hover {
  color: #c4191f;
}

.btn-danger,
.dropdown-item:active {
  background-color: #e11c22;
  color: #ffffff;
}

.btn-danger:hover,
.btn-danger:focus,
.btn.disabled {
  background-color: #e0585c;
  color: #ffffff;
}

.text-light:hover,
.btn-outline-danger:hover,
.btn-outline-danger:focus {
  color: #ffffff;
}

.sidebar-brand {
  background: #e11c22;
}

.simplebar-mask {
  background: #fff;
}

.sidebar,
.header {
  box-shadow: 0px 0px 3px #8c9bb3;
  border-bottom: none;
}

.card,
.dropdown-menu {
  box-shadow: 0px 1px 3px #8c9bb3;
  border: none;
}

.dashboard-card {
  background-image: url(https://masulyablog.sirv.com/secs2022/telU.jpg);
  background-repeat: no-repeat;
  background-position: center;
}

.card-backdrop {
  background: linear-gradient(
    45deg,
    rgba(128, 0, 0, 0.8),
    rgba(225, 28, 34, 0.6),
    rgba(250, 128, 113, 0.25)
  );
  border-radius: 5px;
}

.sidebar-nav .nav-link,
.sidebar-nav .nav-icon,
.sidebar-nav .nav-title {
  color: #6f7783;
}

.sidebar-nav .nav-link:hover,
.sidebar-nav .nav-link:hover .nav-icon,
.sidebar-nav .nav-link.active,
.sidebar-nav .nav-link.active .nav-icon,
.sidebar-nav .nav-group.show .nav-group-toggle,
.sidebar-nav .nav-group.show .nav-group-toggle .nav-icon {
  color: #e11c22 !important;
}

.sidebar-toggler {
  background-color: #e5e5e7;
}

.sidebar-toggler:hover {
  background-color: #ccc;
}

.img-fluid {
  max-width: 100%;
  height: 100%;
  object-fit: cover;
}

.form-control:focus,
.btn-link:focus,
.form-select:focus {
  border-color: rgba(225, 28, 34, 0.25);
  box-shadow: 0 0 0 0.25rem rgba(225, 28, 34, 0.25);
}

.glass-m {
  background: rgba(255, 255, 255, 0.25);
  box-shadow: none;
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.18);
}

.gradient-animation {
  background: linear-gradient(-45deg, #ff0801, #800000, #e11c22, #fa8071);
  background-size: 400% 400%;
  animation: gradient 10s ease infinite;
  height: 100vh;
}

.swal2-confirm {
  margin-right: 15px;
}

.swal2-confirm,
.swal2-cancel,
.swal2-confirm:hover,
.swal2-cancel:hover,
.swal2-confirm:focus,
.swal2-cancel:focus {
  color: #fff;
}

.jadwalcard {
  border: 1px solid rgba(0, 0, 21, 0.175);
  margin: 5px 0;
  border-radius: 15px !important;
}

.jadwal {
  box-shadow: 0px 1px 3px #8c9bb3;
  border: none;
  padding: 1rem;
  border-radius: 10px;
}

.rdt_TableCell,
.rdt_TableCol_Sortable {
  font-size: 1rem !important;
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

@media (max-width: 576px) {
  .gradient-animation {
    height: auto !important;
    padding: 15px !important;
  }
}
